import React, { useState } from 'react'
import Grid from '@pbds/grid'
import Box from '@pbds/box'
import Typography from '@pbds/typography'
import Input from '@pbds/input'
import { PrimaryButton } from '@pbds/buttons'
import styled from 'styled-components'
import Alert from '@pbds/alert'

import BACKGROUND_XL from '../shapes/middlesubscription-xl.svg'
import BACKGROUND_MD from '../shapes/middlesubscription-md.svg'
import BACKGROUND_XS from '../shapes/middlesubscription-xs.svg'
import {
  landingSubscriptionDefaultShape,
  landingSubscriptiontShape,
} from '../shared/proptypes'
import { emailIsValid, doSubscribe } from '../helpers/utils'

const { Row, Col } = Grid
const { Heading, Deck } = Typography

const StyledSubscriptionBackground = styled(Box)(
  ({ theme }) => `
    position: relative;
    border-radius: 8px;
    overflow: hidden;
    background-image: url(${BACKGROUND_XS});
    background-color: #F5F1EB;
    background-position: center;
    background-size: cover;
    padding: 36px 16px;
    margin-top: 72px;
    box-shadow: ${theme.elevation['--elevation-regular-3']};
    @media screen and (min-width: ${theme.breakpoints.md}) {
      background-image: url(${BACKGROUND_MD});
      background-position: center;
      padding: 63px 120px;
      box-shadow: none;
    }
    @media screen and (min-width: ${theme.breakpoints.xl}) {
      background-image: url(${BACKGROUND_XL});
      margin-top: 80px;
      box-shadow: none;
    }
  `
)

const InputWrapper = styled(Box)(
  ({ theme }) => `
  margin-bottom: 16px;
  width: 100%;
  @media screen and (min-width: ${theme.breakpoints.xl}) {
    margin-right: 16px;
    min-width: 400px;
    max-width: 400px;
    margin-bottom: 0;
  }
  @media screen and (min-width: ${theme.breakpoints.md}) {
    margin-right: 16px;
    margin-top: 4px;
    max-width: 300px;
    margin-bottom: 0;
  }
  `
)

const SubscriptionWrapper = styled(Box)(
  ({ theme }) => `
  flex-direction: column;
  align-items: center;
  @media screen and (min-width: ${theme.breakpoints.md}) {
    flex-direction: row;
  }
  `
)

const LandingSubscriptionMiddle = ({
  heading,
  subHeading,
  inputPlaceholder,
  buttonText,
}) => {
  const [email, setEmail] = useState('')
  const [isEmailValid, setIsEmailValid] = useState(true)
  const [isLoading, setLoading] = useState(false)
  const [isSubscribed, setSubscriptionStatus] = useState(false)

  const handleChangeEmail = event => {
    setEmail(event.target.value)
    setIsEmailValid(emailIsValid(event.target.value))
  }

  const subscribe = async () => {
    if (email.length === 0) {
      setIsEmailValid(false)
    } else {
      setLoading(true)
      await doSubscribe(email)
      setLoading(false)
      setSubscriptionStatus(true)
    }
  }

  return (
    <Box maxWidth="1000px" marginX="auto">
      <Grid>
        <Row>
          <Col horizontalAlign={{ xs: 'center', md: 'left' }}>
            <StyledSubscriptionBackground>
              <Heading marginBottom="8px" color="#4A4AF4" variant="--heading-2">
                {heading}
              </Heading>
              <Deck marginBottom="16px">{subHeading}</Deck>
              <SubscriptionWrapper flexDirection="row" flexAlign="middleLeft">
                {!isSubscribed ? (
                  <>
                    <InputWrapper>
                      <Input
                        label="Email address"
                        name="email"
                        id="email"
                        error={!isEmailValid}
                        errorHelperText="Please enter a valid email"
                        value={email}
                        onChange={handleChangeEmail}
                        placeholder={inputPlaceholder}
                      />
                    </InputWrapper>
                    <Box marginBottom={!isEmailValid && 27}>
                      <PrimaryButton
                        isLoading={isLoading}
                        disabled={!isEmailValid}
                        buttonSize="--large-button"
                        onClick={() => subscribe()}
                      >
                        {buttonText}
                      </PrimaryButton>
                    </Box>
                  </>
                ) : (
                  <Alert
                    boldText="Success"
                    text="Submitted! Thanks for subscribing."
                    variant="success"
                  />
                )}
              </SubscriptionWrapper>
            </StyledSubscriptionBackground>
          </Col>
        </Row>
      </Grid>
    </Box>
  )
}

LandingSubscriptionMiddle.defaultProps = landingSubscriptionDefaultShape

LandingSubscriptionMiddle.propTypes = landingSubscriptiontShape

export default LandingSubscriptionMiddle
