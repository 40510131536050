import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import Typograhpy from '@pbds/typography'
import Box from '@pbds/box'

import {
  CardWrapper,
  StyledCard,
  BottomBoxWrapper,
  StyledImage,
  ProfPicWrapper,
} from './styled'
import CategoryBadge from './CategoryBadge'
import ReadTime from './ReadTime'
import AuthorName from './AuthorName'
import AuthorProficePic from './AuthorProfilePic'

import { articleDefaultShape, articleShape } from '../shared/proptypes'

const { Heading, Body } = Typograhpy

const StyledDescriptionBody = styled(Body)(
  ({ theme }) => `
  display: none;
  @media screen and (min-width: ${theme.breakpoints.xl}) {
    display: block;
  }
  `
)

const StyledFlexParent = styled('div')(
  ({ theme }) => `
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  @media screen and (min-width: ${theme.breakpoints.xl}) {
    flex-direction: row;
  }
  `
)

const ImageWrapper = styled('div')(
  ({ theme }) => `
  flex: 1; 
  width: 100%;
  height: 100%;
  `
)

const ContentWrapper = styled('div')(
  ({ theme }) => `
  flex: 1;
  width: 100%;
  margin-left: 0;
  @media screen and (min-width: ${theme.breakpoints.xl}) {
    margin: 26px 10px 26px 24px;
  }
  `
)

const ArticleCardLarge = ({
  title,
  category,
  image,
  author,
  shortDescription,
  lang,
  slug,
  bodyNode,
}) => (
  <CardWrapper hasPadding>
    <Link
      style={{ textDecoration: 'none' }}
      to={`/${lang}/${category.slug}/${slug}`}
    >
      <StyledCard
        flexAlign="topLeft"
        flexDirection="column"
        padding="--spacing-03"
        rel="noopener noreferrer"
        borderRadius="--corner-radius-8"
        backgroundColor="--background-light"
      >
        <StyledFlexParent>
          <ImageWrapper>
            <StyledImage fluid={image.fluid} />
          </ImageWrapper>
          <ContentWrapper>
            <Heading
              textAlign="left"
              marginTop={[
                '--spacing-03',
                '--spacing-03',
                '--spacing-03',
                '--spacing-03',
                0,
              ]}
              marginBottom="--spacing-03"
              variant="--heading-3"
            >
              {title}
            </Heading>
            <StyledDescriptionBody marginBottom="--spacing-03">
              {shortDescription}
            </StyledDescriptionBody>
            <Box flexAlign="middleLeft" flexDirection="row" marginBottom="10px">
              <ProfPicWrapper>
                <AuthorProficePic image={author.profilePicture} />
              </ProfPicWrapper>
              <AuthorName name={author.name} />
            </Box>

            <BottomBoxWrapper>
              <CategoryBadge>{category.name}</CategoryBadge>
              <ReadTime>
                {bodyNode.childMarkdownRemark.timeToRead} MIN READ
              </ReadTime>
            </BottomBoxWrapper>
          </ContentWrapper>
        </StyledFlexParent>
      </StyledCard>
    </Link>
  </CardWrapper>
)

ArticleCardLarge.defaultProps = articleDefaultShape

ArticleCardLarge.propTypes = articleShape

export default ArticleCardLarge
