import React from 'react'
import ArticleTiles from './ArticleTiles'
import ArticlesSectionTitle from './ArticlesSectionTitle'

const ArticlesSection = ({
  articles,
  title,
  hasFeaturedCard,
  adDescription,
  adButtonText,
  adButtonHref,
  adPlace,
  lang,
}) => (
  <>
    <ArticlesSectionTitle title={title} />
    <ArticleTiles
      lang={lang}
      articles={articles}
      hasFeaturedCard={hasFeaturedCard}
      adDescription={adDescription}
      adButtonHref={adButtonHref}
      adButtonText={adButtonText}
      adPlace={adPlace}
    />
  </>
)

ArticlesSection.defaultProps = ArticleTiles.defaultProps

ArticlesSection.propTypes = ArticleTiles.propTypes

export default ArticlesSection
