import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../shared/Layout'
import FeaturedArticle from '../components/FeaturedArticle'
import ArticlesSection from '../components/ArticlesSection'
import LandingSubscriptionMiddle from '../components/LandingSubscriptionMiddle'
import LandingSubscriptionBottom from '../components/LandingSubscriptionBottom'

const BlogLandingPage = ({ data, pageContext, location }) => {
  const {
    title,
    description,
    seo,
    featuredImage,
    featuredTitle,
    featured,
    sections,
    middleSubscriptionBanner,
    bottomSubscriptionBanner,
  } = data.datoCmsBlog
  const { lang, footer } = pageContext
  return (
    <Layout seo={seo} location={location} footer={footer}>
      <FeaturedArticle
        blogTitle={title}
        featuredImage={featuredImage}
        description={description}
        featuredTitle={featuredTitle}
        lang={lang}
        {...featured}
      />
      {/* First section  */}
      <ArticlesSection
        articles={sections[0].articles}
        title={sections[0].title}
        lang={lang}
      />
      <LandingSubscriptionMiddle {...middleSubscriptionBanner} />

      {/* don't render the first section */}
      {sections.map(
        (section, i) => i > 0 && <ArticlesSection lang={lang} {...section} />
      )}
      <LandingSubscriptionBottom {...bottomSubscriptionBanner} />
    </Layout>
  )
}

export const query = graphql`
  query landingPageQuery($locale: String!) {
    datoCmsBlog(locale: { eq: $locale }) {
      title
      description
      seo {
        title
        description
        keywords {
          keyword
        }
        ogTitle
        ogDescription
        ogImage {
          url
        }
      }
      featuredImage {
        fluid {
          ... on DatoCmsFluid {
            aspectRatio
            src
            srcSet
            sizes
          }
        }
        alt
      }
      featuredTitle
      featured {
        id
        slug
        title
        shortDescription
        image {
          fluid {
            ... on DatoCmsFluid {
              aspectRatio
              src
              srcSet
              sizes
            }
          }
          alt
        }
        category {
          slug
          name
        }
        author {
          name
          profilePicture {
            fixed(width: 40, imgixParams: { fm: "jpg", auto: "compress" }) {
              ... on DatoCmsFixed {
                aspectRatio
                src
                srcSet
                sizes
                base64
                width
                height
              }
            }
            alt
          }
        }
        bodyNode {
          childMarkdownRemark {
            timeToRead
          }
        }
      }
      sections {
        id
        title
        hasFeaturedCard
        articles {
          id
          slug
          title
          shortDescription
          image {
            fluid {
              ... on DatoCmsFluid {
                aspectRatio
                src
                srcSet
                sizes
              }
            }
            alt
          }
          category {
            name
            slug
          }
          author {
            name
            profilePicture {
              fixed(width: 40, imgixParams: { fm: "jpg", auto: "compress" }) {
                ... on DatoCmsFixed {
                  aspectRatio
                  src
                  srcSet
                  sizes
                  base64
                  width
                  height
                }
              }
              alt
            }
          }
          bodyNode {
            childMarkdownRemark {
              timeToRead
            }
          }
        }
        adDescription
        adButtonText
        adButtonHref
        adPlace
      }

      middleSubscriptionBanner {
        heading
        subHeading
        inputPlaceholder
        buttonText
      }
      bottomSubscriptionBanner {
        heading
        subHeading
        inputPlaceholder
        buttonText
      }
    }
  }
`

BlogLandingPage.defaultProps = {}

BlogLandingPage.propTypes = {}

export default BlogLandingPage
