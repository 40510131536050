import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import Box from '@pbds/box'
import Typography from '@pbds/typography'
import Grid from '@pbds/grid'
import CategoryBadge from './CategoryBadge'
import ReadTime from './ReadTime'
import { CardWrapper, StyledCard, StyledImage } from './styled'
import {
  categoryDefaultShape,
  imageDefaultProps,
  bodyNodeDefaultShape,
  bodyNodeShape,
} from '../shared/proptypes'

const { Row, Col } = Grid

const { Deck } = Typography

const ArticleCardSmall = ({
  lang,
  title,
  category,
  image,
  slug,
  bodyNode,
  manualReadTime,
}) => (
  <Link
    style={{ textDecoration: 'none' }}
    to={`/${lang}/${category.slug}/${slug}`}
  >
    <CardWrapper hasPadding>
      <StyledCard
        flexAlign="topLeft"
        flexDirection="column"
        padding="--spacing-03"
        rel="noopener noreferrer"
        borderRadius="--corner-radius-8"
        backgroundColor="--background-light"
        paddingBottom={12}
      >
        <Grid margin={false}>
          <Row verticalAlign="middle">
            <Col>
              {image.responsiveImage ? (
                <div
                  style={{
                    width: '100%',
                    minHeight: 200,
                    borderRadius: '8px',
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    backgroundImage: `url(${image.responsiveImage.src})`,
                  }}
                />
              ) : (
                <StyledImage style={{ minHeight: 200 }} fluid={image.fluid} />
              )}
            </Col>
            <Col horizontalAlign="left">
              <Box marginY="24px">
                <ReadTime type="card">
                  {bodyNode.childMarkdownRemark.timeToRead || manualReadTime}{' '}
                  MIN READ
                </ReadTime>
                <Deck marginTop="--spacing-02" variant="--deck-semi-emphasized">
                  {title}
                </Deck>
                <CategoryBadge marginTop="--spacing-03">
                  {category.name}
                </CategoryBadge>
              </Box>
            </Col>
          </Row>
        </Grid>
      </StyledCard>
    </CardWrapper>
  </Link>
)

ArticleCardSmall.defaultProps = {
  lang: 'en',
  title: '',
  category: categoryDefaultShape,
  image: imageDefaultProps,
  slug: '/',
  bodyNode: bodyNodeDefaultShape,
  manualReadTime: 0,
}

ArticleCardSmall.propTypes = {
  lang: PropTypes.string,
  title: PropTypes.string,
  category: categoryDefaultShape,
  image: imageDefaultProps,
  slug: PropTypes.string,
  bodyNode: bodyNodeShape,
  manualReadTime: PropTypes.number,
}

export default ArticleCardSmall
