import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Box from '@pbds/box'
import Grid from '@pbds/grid'
import Typography from '@pbds/typography'

const { Heading } = Typography
const { Row, Col } = Grid

const StyledWrapper = styled(Box)(
  ({ theme }) => `
  margin: auto;
  margin-top: 88px;
  margin-bottom: 8px;
  max-width: 1000px;
  text-align: center;
  @media screen and (min-width: ${theme.breakpoints.xl}) {
    margin-top: 80px;
    text-align: left;
  }
  `
)

const ArticlesSectionTitle = ({ title }) => (
  <StyledWrapper>
    <Grid>
      <Row>
        <Col xl={10}>
          <Heading variant="--heading-2">{title}</Heading>
        </Col>
      </Row>
    </Grid>
  </StyledWrapper>
)

ArticlesSectionTitle.defaultProps = {
  title: '',
}

ArticlesSectionTitle.propTypes = {
  title: PropTypes.string,
}

export default ArticlesSectionTitle
