import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import Grid from '@pbds/grid'
import Box from '@pbds/box'
import Typography from '@pbds/typography'
import styled from 'styled-components'
import AuthorProfilePic from './AuthorProfilePic'
import {
  StyledCard,
  CardWrapper,
  StyledImage,
  ProfPicWrapper,
} from '../components/styled'
import ReadTime from './ReadTime'
import CategoryBadge from './CategoryBadge'
import AuthorName from './AuthorName'

import BACKGROUND_XL from '../shapes/featured-xl.svg'
import BACKGROUND_MD from '../shapes/featured-md.svg'
import BACKGROUND_XS from '../shapes/featured-xs.svg'
import {
  imageFixedDefaultShape,
  imageFixedShape,
  bodyNodeShape,
  bodyNodeDefaultShape,
  categoryDefaultShape,
  categoryShape,
} from '../shared/proptypes'

const { Heading, Deck, Body } = Typography

const { Row, Col } = Grid

const StyledBox = styled(Box)(
  ({ theme }) => `
  margin: 32px 0;
  @media screen and (min-width: ${theme.breakpoints.md}) {
    margin: 56px 0;
  }
  @media screen and (min-width: ${theme.breakpoints.xl}) {
    margin: 72px 0;
  }
  `
)

const StyledBody = styled(Body)(
  ({ theme, visibleFrom, visibleTo }) => `
  margin: 16px 0;
  display: ${visibleTo === 'xs' ? 'none' : 'block'};
  @media screen and (min-width: ${theme.breakpoints[visibleFrom]}) {
    display: inline-block;
  }
  @media screen and (min-width: ${theme.breakpoints[visibleTo]}) {
    display: none;
  }
  @media screen and (min-width: ${theme.breakpoints.md}) {
    margin: 0 0 16px 0;
  }
  @media screen and (min-width: ${theme.breakpoints.xl}) {
    margin: 0 0 16px 0;
  }
  
  `
)

const StyledDeck = styled(Deck)(
  ({ theme }) => `
  display: none;
  @media screen and (min-width: ${theme.breakpoints.xl}) {
    display: block;
  }
  `
)

const AuthorWrapper = styled(Box)(
  ({ theme }) => `
  margin-bottom: 10px;
  @media screen and (min-width: ${theme.breakpoints.md}) {
    margin-bottom: 14px;
  }
  @media screen and (min-width: ${theme.breakpoints.xl}) {
    margin-bottom: 0;
  }
  `
)

const DescriptionWrapper = styled(Box)(
  ({ theme }) => `
  margin-left: 0;
  @media screen and (min-width: ${theme.breakpoints.md}) {
    margin-left: 6px;
  }
  @media screen and (min-width: ${theme.breakpoints.lg}) {
    margin-left: 12px;
  }
  @media screen and (min-width: ${theme.breakpoints.xl}) {
    margin: 56px 0 56px 15px;
  }
  `
)

const StyledBackground = styled('div')(
  ({ theme }) => `
  z-index: -1;
  background-color: #f5f1eb;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  background-image: url(${BACKGROUND_XS});
  position: absolute;
  width: 100%;
  height: 590px;
  @media screen and (min-width: ${theme.breakpoints.md}) {
    background-image: url(${BACKGROUND_MD});
    height: 443px;
  }
  @media screen and (min-width: ${theme.breakpoints.xl}) {
    background-image: url(${BACKGROUND_XL});
    height: 650px;
  }
  `
)

const CustomHeading = styled('h1')(
  ({ theme }) => `
  font-size: 32px;
  font-family: Boing;
  font-weight: 500;
  margin-bottom: 16px;
  @media screen and (min-width: ${theme.breakpoints.md}) {
    font-size: 40px;
  }
  @media screen and (min-width: ${theme.breakpoints.xl}) {
    font-size: 64px;
  }
  `
)

const ReadTimeWrapper = styled('span')(
  ({ theme }) => `
  display: none;
  margin-left: 32px;
  @media screen and (min-width: ${theme.breakpoints.xl}) {
    display: inline-block;
  }
`
)

const FeaturedArticle = ({
  blogTitle,
  description,
  title,
  slug,
  category,
  shortDescription,
  author,
  featuredImage,
  featuredTitle,
  bodyNode,
  lang,
}) => (
  <div style={{ position: 'relative' }}>
    <StyledBackground />
    <Grid>
      <Row>
        <Col horizontalAlign="center">
          <StyledBox>
            <CustomHeading>{blogTitle}</CustomHeading>
            <Deck variant="--deck-regular">{description}</Deck>
          </StyledBox>
        </Col>
      </Row>
    </Grid>
    <div style={{ maxWidth: 1200, margin: 'auto' }}>
      <Grid limitWidth={false}>
        <Row>
          <Col>
            <CardWrapper>
              <Link
                style={{ textDecoration: 'none' }}
                to={`/${lang}/${category.slug}/${slug}`}
              >
                <StyledCard
                  padding="--spacing-03"
                  backgroundColor="white"
                  borderRadius="--corner-radius-8"
                >
                  <Grid limitWidth={false} margin={false}>
                    <Row verticalAlign="middle" mdReverse>
                      <Col xs={12} md={6}>
                        <StyledImage fluid={featuredImage.fluid} />
                      </Col>
                      <Col xs={12} md={6}>
                        <DescriptionWrapper>
                          <StyledBody
                            visibleTo="xs"
                            visibleFrom="md"
                            color="#4A4AF4"
                            variant="--body-01-regular"
                          >
                            {featuredTitle}
                          </StyledBody>
                          <StyledBody
                            visibleFrom="xs"
                            visibleTo="md"
                            color="#4A4AF4"
                            variant="--body-02-regular"
                          >
                            {featuredTitle}
                          </StyledBody>

                          <ReadTimeWrapper>
                            <ReadTime>
                              {bodyNode.childMarkdownRemark.timeToRead} MIN READ
                            </ReadTime>
                          </ReadTimeWrapper>
                          <Box maxWidth="450px">
                            <Heading
                              marginBottom="--spacing-03"
                              variant="--heading-2"
                            >
                              {title}
                            </Heading>
                          </Box>
                          <StyledDeck
                            marginBottom="24px"
                            variant="--deck-regular"
                          >
                            {shortDescription}
                          </StyledDeck>

                          <Grid margin={false}>
                            <Row verticalAlign="middle">
                              <Col xs={12} xl={6}>
                                <AuthorWrapper
                                  flexDirection="row"
                                  flexAlign="middleLeft"
                                >
                                  <ProfPicWrapper>
                                    <AuthorProfilePic
                                      image={author.profilePicture}
                                    />
                                  </ProfPicWrapper>
                                  <AuthorName name={author.name} />
                                </AuthorWrapper>
                              </Col>
                              <Col xs={0} xl={6} horizontalAlign="end">
                                <CategoryBadge>{category.name}</CategoryBadge>
                              </Col>
                              <Col xs={12} xl={0}>
                                <Box flexDirection="row" flexAlign="middleLeft">
                                  <CategoryBadge>{category.name}</CategoryBadge>
                                  <span style={{ marginLeft: 'auto' }}>
                                    <ReadTime>
                                      {bodyNode.childMarkdownRemark.timeToRead}{' '}
                                      MIN READ
                                    </ReadTime>
                                  </span>
                                </Box>
                              </Col>
                            </Row>
                          </Grid>
                        </DescriptionWrapper>
                      </Col>
                    </Row>
                  </Grid>
                </StyledCard>
              </Link>
            </CardWrapper>
          </Col>
        </Row>
      </Grid>
    </div>
  </div>
)

FeaturedArticle.defaultProps = {
  blogTitle: '',
  description: '',
  title: '',
  slug: '',
  category: categoryDefaultShape,
  shortDescription: '',
  author: {
    name: '',
    profilePicture: imageFixedDefaultShape,
  },
  featuredImage: imageFixedDefaultShape,
  featuredTitle: '',
  bodyNode: bodyNodeDefaultShape,
  lang: 'en',
}

FeaturedArticle.propTypes = {
  blogTitle: PropTypes.string,
  description: PropTypes.string,
  title: PropTypes.string,
  slug: PropTypes.string,
  category: categoryShape,
  shortDescription: PropTypes.string,
  author: {
    name: PropTypes.string,
    profilePicture: imageFixedShape,
  },
  featuredImage: imageFixedShape,
  featuredTitle: '',
  bodyNode: bodyNodeShape,
  lang: 'en',
}

export default FeaturedArticle
