import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Box from '@pbds/box'
import Typograghy from '@pbds/typography'
import { PrimaryButton } from '@pbds/buttons'
import { CardWrapper } from './styled'

import BACKGROUND_XL from '../shapes/ad-xl.svg'
import BACKGROUND_MD from '../shapes/ad-md.svg'
import BACKGROUND_XS from '../shapes/ad-xs.svg'

const { Heading } = Typograghy

const AdBox = styled(Box)(
  ({ theme }) => `
  position: relative;
  overflow: hidden;
  text-align: center;
  padding: 32px 16px;
  background-size: cover;
  background-position: center;
  background-image: url(${BACKGROUND_XS});
  @media screen and (min-width: ${theme.breakpoints.md}) {
    padding: 40px 16px;
    background-image: url(${BACKGROUND_MD});
  }
  @media screen and (min-width: ${theme.breakpoints.xl}) {
    background-image: url(${BACKGROUND_XL});
    padding: 38px 16px;
    text-align: left;
  }
  position: relative;
    text-decoration: none;
    box-shadow: ${theme.elevation['--elevation-regular-3']};
    color: ${theme.colors['--text-dark']};
    height: 100%;

    &:before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      border-radius: inherit;
      opacity: 0;
      box-shadow: ${theme.elevation['--elevation-emphasized-4']};
      transition: opacity 0.2s ease;
    }
  
  `
)

const AdCardBoxHeadingWrapper = styled('div')(
  ({ theme }) => `
  margin-bottom: 16px;
  @media screen and (min-width: ${theme.breakpoints.xl}) {
    margin-bottom: 60px;
  }
  `
)

const AdBoxButtonWrapper = styled('div')(
  ({ theme }) => `
    margin: auto;
    @media screen and (min-width: ${theme.breakpoints.xl}) {
      margin: 0;
    }
  `
)

const AdCard = ({ adDescription, adButtonText, adButtonHref }) => (
  <CardWrapper hasPadding>
    <AdBox
      flexAlign="topLeft"
      flexDirection="column"
      borderRadius="--corner-radius-8"
      backgroundColor="#F5F1EB"
    >
      <AdCardBoxHeadingWrapper>
        <Heading color="#4A4AF4" variant="--heading-2">
          {adDescription}{' '}
        </Heading>
      </AdCardBoxHeadingWrapper>

      <AdBoxButtonWrapper>
        <PrimaryButton
          href={adButtonHref}
          buttonType="a"
          buttonSize="--large-button"
        >
          {adButtonText}
        </PrimaryButton>
      </AdBoxButtonWrapper>
    </AdBox>
  </CardWrapper>
)

AdCard.defaultProps = {
  adDescription: '',
  adButtonText: '',
  adButtonHref: '',
}

AdCard.propTypes = {
  adDescription: PropTypes.string,
  adButtonText: PropTypes.string,
  adButtonHref: PropTypes.string,
}

export default AdCard
