import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@pbds/grid'
import Box from '@pbds/box'
import ArticleCard from './ArticleCard'
import ArticleCardSmall from './ArticleCardSmall'
import ArticleCardLarge from './ArticleCardLarge'
import AdCard from './AdCard'
import { articleDefaultShape, articleShape } from '../shared/proptypes'

const { Row, Col } = Grid

const AD_CARD_TYPE = 'adCard'

const addAdToArticles = (articles, adPlace, adDescription) => {
  const hasAdCard = articles.find(article => article.type)
  // watch for hot reloading
  if (!adDescription || hasAdCard) {
    return articles
  }
  articles.splice(adPlace - 1, 0, { type: AD_CARD_TYPE })
  return articles
}

const ArticleTiles = ({
  articles,
  hasFeaturedCard,
  adDescription,
  adButtonText,
  adButtonHref,
  adPlace,
  lang,
}) => {
  const articlesWithAd = addAdToArticles(articles, adPlace, adDescription)
  return (
    <Box maxWidth="1000px" marginX="auto">
      <Grid>
        <Row>
          {articlesWithAd.map((article, i) => {
            if (hasFeaturedCard && i === 0) {
              return (
                <Col xs={12} mdOffset={2} md={8} xlOffset={0} xl={8}>
                  <ArticleCardLarge {...article} lang={lang} />
                </Col>
              )
            } else {
              return (
                <>
                  <Col xs={0} xl={4}>
                    {article.type ? (
                      <AdCard
                        adDescription={adDescription}
                        adButtonText={adButtonText}
                        adButtonHref={adButtonHref}
                      />
                    ) : (
                      <ArticleCard lang={lang} {...article} />
                    )}
                  </Col>
                  <Col xs={12} mdOffset={2} md={8} xl={0}>
                    {article.type ? (
                      <AdCard
                        adDescription={adDescription}
                        adButtonText={adButtonText}
                        adButtonHref={adButtonHref}
                      />
                    ) : (
                      <ArticleCardSmall lang={lang} {...article} />
                    )}
                  </Col>
                </>
              )
            }
          })}
        </Row>
      </Grid>
    </Box>
  )
}

ArticleTiles.defaultProps = {
  articles: [articleDefaultShape],
  hasFeaturedCard: false,
  adDescription: '',
  adButtonText: '',
  adButtonHref: '',
  adPlace: 0,
  lang: 'en',
}

ArticleTiles.propTypes = {
  articles: PropTypes.arrayOf(articleShape),
  hasFeaturedCard: PropTypes.bool,
  adDescription: PropTypes.string,
  adButtonText: PropTypes.string,
  adButtonHref: PropTypes.string,
  adPlace: PropTypes.string,
  lang: PropTypes.string,
}

export default ArticleTiles
