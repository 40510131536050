import React from 'react'
import { Link } from 'gatsby'
import Typography from '@pbds/typography'
import CategoryBadge from './CategoryBadge'
import ReadTime from './ReadTime'
import {
  CardWrapper,
  StyledCard,
  StyledImage,
  BottomBoxWrapper,
} from './styled'
import { articleDefaultShape, articleShape } from '../shared/proptypes'

const { Heading } = Typography

const ArticleCard = ({
  title,
  category,
  image,
  lang,
  slug,
  bodyNode,
  manualReadTime,
}) => {
  return (
    <CardWrapper hasPadding>
      <Link
        style={{ textDecoration: 'none' }}
        to={`/${lang}/${category.slug}/${slug}`}
      >
        <StyledCard
          flexAlign="topLeft"
          flexDirection="column"
          padding="--spacing-03"
          rel="noopener noreferrer"
          borderRadius="--corner-radius-8"
          backgroundColor="--background-light"
          paddingBottom={12}
        >
          {image.responsiveImage ? (
            <div
              style={{
                width: '100%',
                height: 144,
                borderRadius: '8px',
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundImage: `url(${image.responsiveImage.src})`,
              }}
            />
          ) : (
            <StyledImage style={{ height: 144 }} fluid={image.fluid} />
          )}
          <Heading
            marginTop="--spacing-03"
            marginBottom="--spacing-06"
            variant="--heading-4"
          >
            {title}
          </Heading>
          <BottomBoxWrapper>
            <CategoryBadge>{category.name}</CategoryBadge>
            <ReadTime>
              {bodyNode.childMarkdownRemark.timeToRead || manualReadTime} MIN
              READ
            </ReadTime>
          </BottomBoxWrapper>
        </StyledCard>
      </Link>
    </CardWrapper>
  )
}

ArticleCard.defaultProps = articleDefaultShape

ArticleCard.propTypes = articleShape

export default ArticleCard
